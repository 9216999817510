import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Front Squats 4×6\\@80%`}</p>
    <p>{`Shoulder Bridge 4×6`}</p>
    <p>{`then,`}</p>
    <p>{`14:00 AMRAP of:`}</p>
    <p>{`7-Hang Squat Cleans (135/95)(RX+165/115)`}</p>
    <p>{`14-Wall Balls (20/14)(RX+ 30/20)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      